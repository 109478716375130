import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";
import { Form, Container } from "react-bootstrap";  // Button, Row, Coln InputGroup, FormControl -Col pour afficher les dates  sur meme ligne
import Select from 'react-select';

import { DonneesUtilisateurContext } from '../../contexte.js';

import { appelGetSetInfosObjetsFormActSess, appelRecupInfoGet, confirmationSiCLickSUrLien } from '../../fonctionsDiv.js';
import { apiFormGetInfosDiv, apiFormModif, apiPutFormObjs } from '../../Datas/dataFormations.js'

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, {MessageErreurApi, MessageInfoChampsObligatoires} from '../../Components/Messages.jsx';
import ButtonDeBase from '../../Components/ButtonsCustom.jsx';
import FormRHF from "../../Components/Form/Form";
import FormControl from "../../Components/Form/FormControl";
import FormSelect from "../../Components/Form/FormSelect";
import FormHidden from "../../Components/Form/FormHidden";
import FormDemandeChangementFormation from '../../Components/FormDemandeChangementFormation.jsx';


// inspi d'un code mellant react-bootstrap et react-hook-form ;  https://codesandbox.io/s/y74yf?file=/src/App.js:517-530
// de base le message required était ainsi :
//{...register("CONTENU_FORMATION", { required: "Ce champs est obligatoire",  maxLength: 2 })}
// mais ai changé pour faire comme le https://codesandbox.io/s/react-hook-form-apply-validation-q5m00 (2d code de https://react-hook-form.com/get-started#Quickstart)

/**
 * Return Formulaire étape 2 (données simples de la formation)
 *
 * @component
 * @summary page étape 2
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape2(props) {
    let numEtape = 2;

    const formationNumber = props.formationNumber;
    
    const navigate = useNavigate(); // utile pour changer de page
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLHEO_PARCOURS_DE_FORMATION, SetDataLHEO_PARCOURS_DE_FORMATION] = useState(); // pour recup données du select
    const [dataNiveauEuro, SetDataNiveauEuro] = useState();
    const [dataLHEO_OBJECTIF_GENERAL_FORMATION, SetDataLHEO_OBJECTIF_GENERAL_FORMATION] = useState();
    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans  enregistrement

    const [dataLoaded, setDataLoaded] = useState(false);
    const [messageProbleme, setMessageProbleme] = useState(null);

    //pour effectuer des actions en fonction de la saisie de l'utilisateur
    const [urlWeb2Visible, setUrlWeb2Visible] = useState(false);
    const [urlWeb3Visible, setUrlWeb3Visible] = useState(false);

    const [dataPRST4_ECHELONS, SetDataPRST4_ECHELONS] = useState(); // pour recup données du select
    
    const [dataFormesPrst4, SetDataFormesPrst4] = useState(); // ici la cible ne contiendra pas les données brutes de l'api mais un tab d'objet ,ex : 0: Object { value: xx, label: "xxx } 
    const [tabObjFormesPrst4Preselectionnes, SetTabObjFormesPrst4Preselectionnes] = useState([]); // pour que le reactselect affiche ce qui a déjà été selectionné

    const [dataPublicsPrst4, SetDataPublicsPrst4] = useState(); 
    const [tabObjPublicsPrst4Preselectionnes, SetTabObjPublicsPrst4Preselectionnes] = useState([]);

    const [dataSecteursPrst4, SetDataSecteursPrst4] = useState(); 
    const [tabObjSecteursPrst4Preselectionnes, SetTabObjSecteursPrst4Preselectionnes] = useState([]);

    const [dataThematiquesPrst4, SetDataThematiquesPrst4] = useState(); 
    const [tabObjThematiquesPrst4Preselectionnes, SetTabObjThematiquesPrst4Preselectionnes] = useState([]);

    const [estFormationPrst4, setEstFormationPrst4] = useState(false); 
    var estFormationPrst4_varloc = false;

    var tabFormesPrst4Recuperes = []; // va servir a alimenter un tableau d'objet permettant de réafficher les sélectionnées dans react-select
    var tabPublicsPrst4Recuperes = [];
    var tabSecteursPrst4Recuperes = [];
    var tabThematiquesPrst4Recuperes = [];

    async function recupererInfosPrincipales() {
      const dataR = await apiFormGetInfosDiv(utilisateurBearer, formationNumber);
      if(global.config.modeDebugApp == 1) console.log('retour apiFormGetInfosDiv dans recupererInfosPrincipales : ', dataR);
      switch (dataR.statusCode) {
          case 200:
           if(dataR.dataApi.formationDataPrincipales.Urlweb1 != null) setUrlWeb2Visible(true);
           if(dataR.dataApi.formationDataPrincipales.Urlweb2 != null) setUrlWeb3Visible(true);

           if(dataR.dataApi.formationDataPrincipales.ID_PROVENANCE == 70)  {// 70 si ID_PROVENANCE = 70, c'est une formation prst4
            setEstFormationPrst4(true);
            estFormationPrst4_varloc = true; //obligé pour que la fct qui appel recupererInfosPrincipales est la valeur à jour

            tabFormesPrst4Recuperes = dataR.dataApi.lesFormesPrst4;
            tabPublicsPrst4Recuperes = dataR.dataApi.lesPublicsPrst4;
            tabSecteursPrst4Recuperes = dataR.dataApi.lesSecteursPrst4;
            tabThematiquesPrst4Recuperes = dataR.dataApi.lesThematiquesPrst4;
           }

            // const maDate = new Date(dataR.dataApi.DATE_MODIF); pas utile, fait par l'api
            // dataR.dataApi.DATE_MODIFfr = maDate.toLocaleDateString("fr");
            // dataR.dataApi.DATE_CREATIONfr = new Date(dataR.dataApi.DATE_CREATION).toLocaleDateString("fr");
            setDataApi(dataR.dataApi.formationDataPrincipales);
            //console.log("dataApi", dataR.dataApi)
            return true;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        return false;
      }
    }

    //pour effectuer des actions en fonction de la saisie de l'utilisateur
    function gestionEvenements(value, name){
      setAumoinsUneModif(true); document.getElementById("hidden_marque_une_modif").value = 1;
      if(global.config.modeDebugApp == 1) console.log (`modif de ${name} : ${value[name]}`);

      if(name == "Urlweb1") {
        if(value[name] != "") setUrlWeb2Visible(true); else setUrlWeb2Visible(false);
      }
      else if(name == "Urlweb2") {
        if(value[name] != "") setUrlWeb3Visible(true); else setUrlWeb3Visible(false);
      }
    }

    const onSubmit = async (values) => {
      for(let i in values) if(values[i] === "") values[i] = null; // sinon génère une erreur à cause de ID_LHEO_OBJECTIF_GENERAL_FORMATION if(values.ID_LHEO_OBJECTIF_GENERAL_FORMATION == "") values.ID_LHEO_OBJECTIF_GENERAL_FORMATION = null;
      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));

      if(values.Urlweb1 != null && values.Urlweb1.length > 4 && values.Urlweb1.substring(0,8) != 'https://') {
        var ret = confirm(`La plupart des urls commencent maintenant par https://.\r\nVous confirmez que votre site n'est pas accessible avec une telle url ?`);
        if (ret == false) {
          alert(`Parfait, veuillez donc modifier l'url\r\n${values.Urlweb1}`);
          var ob = document.getElementById("Urlweb1");
          ob.focus();
          return false;
        }
      }

      if(estFormationPrst4) {
        if(values.ID_PRST4_ECHELONS == null) {
          alert(`Le renseignement de l'échelon est obligatoire.`);
          var ob = document.getElementById("ID_PRST4_ECHELONS");
          ob.focus();
          return false;
        }
        if(modifFormesPrst4 == true) {
          if(formesPrst4Selectionne.length > global.config.nbFormesPrst4MaxParFormation) {
            alert(`Vous ne pouvez pas sélectionner plus de ${global.config.nbFormesPrst4MaxParFormation} formes.`);
            return false;
          }
          else if(formesPrst4Selectionne.length == 0) {
            alert(`Vous devez sélectionner au moins une forme.`);
            return false;
          }
        }
        else if(!tabObjFormesPrst4Preselectionnes || tabObjFormesPrst4Preselectionnes.length == 0){ // si pas de modif on doit verifier qu'il y en avait au moins un au chargement
          alert(`Vous devez sélectionner au moins une forme.`);
          return false;
        }

        if(modifPublicsPrst4 == true) {
          if(publicsPrst4Selectionne.length > global.config.nbPublicsPrst4MaxParFormation) {
            alert(`Vous ne pouvez pas sélectionner plus de ${global.config.nbPublicsPrst4MaxParFormation} publics.`);
            return false;
          }
          else if(publicsPrst4Selectionne.length == 0) {
            alert(`Vous devez sélectionner au moins un public.`);
            return false;
          }
        }
        else if(!tabObjPublicsPrst4Preselectionnes || tabObjPublicsPrst4Preselectionnes.length == 0){ // si pas de modif on doit verifier qu'il y en avait au moins un au chargement
          alert(`Vous devez sélectionner au moins un public.`);
          return false;
        }

        if(modifSecteursPrst4 == true) {
          if(secteursPrst4Selectionne.length > global.config.nbSecteursPrst4MaxParFormation) {
            alert(`Vous ne pouvez pas sélectionner plus de ${global.config.nbSecteursPrst4MaxParFormation} secteurs.`);
            return false;
          }
          else if(secteursPrst4Selectionne.length == 0) {
            alert(`Vous devez sélectionner au moins un secteur.`);
            return false;
          }
        }
        else if(!tabObjSecteursPrst4Preselectionnes || tabObjSecteursPrst4Preselectionnes.length == 0){ // si pas de modif on doit verifier qu'il y en avait au moins un au chargement
          alert(`Vous devez sélectionner au moins un secteur.`);
          return false;
        }

        if(modifThematiquesPrst4 == true) {
          if(thematiquesPrst4Selectionne.length > global.config.nbThematiquesPrst4MaxParFormation) {
            alert(`Vous ne pouvez pas sélectionner plus de ${global.config.nbThematiquesPrst4MaxParFormation} thématiques.`);
            return false;
          }
          else if(thematiquesPrst4Selectionne.length == 0) {
            alert(`Vous devez sélectionner au moins une thématique.`);
            return false;
          }
        }
        else if(!tabObjThematiquesPrst4Preselectionnes || tabObjThematiquesPrst4Preselectionnes.length == 0){ // si pas de modif on doit verifier qu'il y en avait au moins un au chargement
          alert(`Vous devez sélectionner au moins une thématique.`);
          return false;
        }

      } // fin if(estFormationPrst4)

      setAumoinsUneModif(false);
      setDataLoaded(false); // utile pour pas que l'utilisateur soumette plusieurs fois
      var dataR = await apiFormModif(utilisateurBearer, values, formationNumber) // on envoie sans faire JSON.stringify
      if(global.config.modeDebugApp == 1) console.log('retour apiFormModif : ', dataR);

      switch (dataR.statusCode) {
          case 200: 
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
              break;
      }

      //enregistrements des choix multiples
      if(estFormationPrst4) {
        if (modifFormesPrst4 == true) {
          // on adapte le tableau d'objet qu'on va envoyer pour qu'il ait les 2 propriétés attendues
          var aAenregistrer = [];
          formesPrst4Selectionne.forEach((el) => {
            let ligne = {'ID_ACTIONS_FORMATION': formationNumber, 'ID_PRST4_FORMES':  el.value}
            aAenregistrer.push(ligne);  
          });
          if(global.config.modeDebugApp == 1) console.log("aAenregistrer", aAenregistrer);

          // apiPutFormObjs(utilisateurBearer, what, formationNumber,  method='PUT', idObjet='', datasToPost=[])
          var dataR = await apiPutFormObjs(utilisateurBearer, "formes-prst4", formationNumber, 'PUT', '', aAenregistrer);
          if(global.config.modeDebugApp == 1) console.log('retour apiPutFormObjs avec formes-prst4 : ', dataR);
          
          switch (dataR.statusCode) {
              case 200:
                  /*if (dataR.dataApi < 1) { //nok car il peut y avoir 0 si l'utilisateur déselectione tout, c'est optionnel
                      setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A5]"); // Problème technique par défaut
                      return false;
                  }*/
                break;
              case 401:
                  setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                  return false;
              case 400:
                  //setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "l'action ne peut être associée à plus de XXX modalités pédagogiques"
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A61]"); // Problème technique par défaut
                  return false;
              default:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A62]"); // Problème technique par défaut
                  return false;
          }
        }
        if (modifPublicsPrst4 == true) {
          var aAenregistrer = [];
          publicsPrst4Selectionne.forEach((el) => {
            let ligne = {'ID_ACTIONS_FORMATION': formationNumber, 'ID_PRST4_PUBLICS':  el.value}
            aAenregistrer.push(ligne);  
          });
          if(global.config.modeDebugApp == 1) console.log("aAenregistrer", aAenregistrer);

          var dataR = await apiPutFormObjs(utilisateurBearer, "publics-prst4", formationNumber, 'PUT', '', aAenregistrer);
          if(global.config.modeDebugApp == 1) console.log('retour apiPutFormObjs avec publics-prst4 : ', dataR);
          
          switch (dataR.statusCode) {
              case 200:
                break;
              case 401:
                  setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                  return false;
              case 400:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A63]"); // Problème technique par défaut
                  return false;
              default:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A64]"); // Problème technique par défaut
                  return false;
          }
        }
        if (modifSecteursPrst4 == true) {
          var aAenregistrer = [];
          secteursPrst4Selectionne.forEach((el) => {
            let ligne = {'ID_ACTIONS_FORMATION': formationNumber, 'ID_PRST4_SECTEURS_ACTIVITES':  el.value}
            aAenregistrer.push(ligne);  
          });
          if(global.config.modeDebugApp == 1) console.log("aAenregistrer", aAenregistrer);

          var dataR = await apiPutFormObjs(utilisateurBearer, "secteurs-prst4", formationNumber, 'PUT', '', aAenregistrer);
          if(global.config.modeDebugApp == 1) console.log('retour apiPutFormObjs avec secteurs-prst4 : ', dataR);
          
          switch (dataR.statusCode) {
              case 200:
                break;
              case 401:
                  setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                  return false;
              case 400:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A65]"); // Problème technique par défaut
                  return false;
              default:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A66]"); // Problème technique par défaut
                  return false;
          }
        }
        if (modifThematiquesPrst4 == true) {
          var aAenregistrer = [];
          thematiquesPrst4Selectionne.forEach((el) => {
            let ligne = {'ID_ACTIONS_FORMATION': formationNumber, 'ID_PRST4_THEMATIQUES':  el.value}
            aAenregistrer.push(ligne);  
          });
          if(global.config.modeDebugApp == 1) console.log("aAenregistrer", aAenregistrer);

          var dataR = await apiPutFormObjs(utilisateurBearer, "thematiques-prst4", formationNumber, 'PUT', '', aAenregistrer);
          if(global.config.modeDebugApp == 1) console.log('retour apiPutFormObjs avec thematiques-prst4 : ', dataR);
          
          switch (dataR.statusCode) {
              case 200:
                break;
              case 401:
                  setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                  return false;
              case 400:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A65]"); // Problème technique par défaut
                  return false;
              default:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A66]"); // Problème technique par défaut
                  return false;
          }
        }
      }

      // pour que le systeme calcule la validité Lhéo après l'enregistrement....
      var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, null, null, props.SetInfosGenFormation, setMessageProbleme);

      if(retAppel == true) {
        //var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
        //objaAmodif["etape2"] = "ok";
        //props.SetInfosGenFormation(objaAmodif);
        alert("L'enregistrement s'est bien effectué.\r\n\r\nVous allez maintenant être dirigé à l'étape suivante \"Contact(s) de la formation\".");
        navigate(`/formation/etape3/${formationNumber}`);
      }
      else setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A11]"); // Problème technique par défaut
      setDataLoaded(true); // utile pour pas que l'utilisateur soumette plusieurs fois
    };
  
    const onError = (error) => {
      console.log("ERROR:::", error);
    };
    /// eslint-disable-next-line no-unused-vars
  

    async function chargementsConsecutifs() { //attention si 2 asynchrones sont lancées en même temps à la suite, il faut faire un await sur le premier :
      setDataLoaded(false);
      var pb = false;

      var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_NIVEAU", setMessageProbleme)
      if(ret != null) SetDataNiveauEuro(ret); else setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut

      var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_PARCOURS_DE_FORMATION", setMessageProbleme);
      if(ret != null) SetDataLHEO_PARCOURS_DE_FORMATION(ret); else setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut

      var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_OBJECTIF_GENERAL_FORMATION", setMessageProbleme);
      if(ret != null) SetDataLHEO_OBJECTIF_GENERAL_FORMATION(ret); else setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut

      if(ret != null) ret = await recupererInfosPrincipales();

      // recuperation des données pour les listes pour react-select attend un tableau d'objet, ex : 0: Object { value: xxxx, label: xxxx }
      // on recupere aussi les objets préselectionnés
      if(estFormationPrst4_varloc) {
        var ret = await appelRecupInfoGet(utilisateurBearer, "PRST4_ECHELONS", setMessageProbleme);
        if(ret != null) SetDataPRST4_ECHELONS(ret); else setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
  
        if(pb == false) {
          var ret = await appelRecupInfoGet(utilisateurBearer, "PRST4_FORMES", setMessageProbleme);
          if(ret != null) {
            var donneesPourSelect = [];
            var preselectionnes = [];
            ret.forEach((el) => {
                let ligne = {'value': el.id, 'label':  el.lib};
                donneesPourSelect.push(ligne);
                tabFormesPrst4Recuperes.forEach((elPreselectionnes) => { 
                    if (elPreselectionnes.ID_PRST4_FORMES ==  el.id)  preselectionnes.push(ligne);
                });
            });
            //console.log("donneesPourSelect :",  donneesPourSelect);
            //console.log("les formes preselectionnes :",  preselectionnes);
            SetDataFormesPrst4(donneesPourSelect); // attention ici la cible ne contiendra pas les données brutes de l'api mais un tab d'objet
            if(preselectionnes != null && preselectionnes.length > 0) SetTabObjFormesPrst4Preselectionnes(preselectionnes);
          } else {pb = true; codeErreur = "110a;"}
        }
        if(pb == false) {
          var ret = await appelRecupInfoGet(utilisateurBearer, "PRST4_PUBLICS", setMessageProbleme);
          if(ret != null) {
            var donneesPourSelect = [];
            var preselectionnes = [];
            ret.forEach((el) => {
                let ligne = {'value': el.id, 'label':  el.lib};
                donneesPourSelect.push(ligne);
                tabPublicsPrst4Recuperes.forEach((elPreselectionnes) => {
                    if (elPreselectionnes.ID_PRST4_PUBLICS ==  el.id)  preselectionnes.push(ligne);
                });
            });
            SetDataPublicsPrst4(donneesPourSelect);
            if(preselectionnes != null && preselectionnes.length > 0) SetTabObjPublicsPrst4Preselectionnes(preselectionnes);
          } else {pb = true; codeErreur = "110b;"}
        }
        if(pb == false) {
          var ret = await appelRecupInfoGet(utilisateurBearer, "PRST4_SECTEURS_ACTIVITES", setMessageProbleme);
          if(ret != null) {
            var donneesPourSelect = [];
            var preselectionnes = [];
            ret.forEach((el) => {
                let ligne = {'value': el.id, 'label':  el.lib};
                donneesPourSelect.push(ligne);
                tabSecteursPrst4Recuperes.forEach((elPreselectionnes) => {
                    if (elPreselectionnes.ID_PRST4_SECTEURS_ACTIVITES ==  el.id)  preselectionnes.push(ligne);
                });
            });
            SetDataSecteursPrst4(donneesPourSelect); 
            if(preselectionnes != null && preselectionnes.length > 0) SetTabObjSecteursPrst4Preselectionnes(preselectionnes);
          } else {pb = true; codeErreur = "110c;"}
        }
        if(pb == false) {
          var ret = await appelRecupInfoGet(utilisateurBearer, "PRST4_THEMATIQUES", setMessageProbleme);
          if(ret != null) {
            var donneesPourSelect = [];
            var preselectionnes = [];
            ret.forEach((el) => {
                let ligne = {'value': el.id, 'label':  el.lib};
                donneesPourSelect.push(ligne);
                tabThematiquesPrst4Recuperes.forEach((elPreselectionnes) => {
                    if (elPreselectionnes.ID_PRST4_THEMATIQUES ==  el.id)  preselectionnes.push(ligne);
                });
            });
            SetDataThematiquesPrst4(donneesPourSelect); 
            if(preselectionnes != null && preselectionnes.length > 0) SetTabObjThematiquesPrst4Preselectionnes(preselectionnes);
          } else {pb = true; codeErreur = "110a;"}
        }
      }
      // recuperation des données pour les listes pour react-select fin      

      setDataLoaded(true);
    }


    useEffect(() => {
      if(global.config.modeDebugApp == 1) console.log('useffect FormEtape2');
      document.title = `Edition de la formation ${formationNumber}`;
      setMessageProbleme(null);
      chargementsConsecutifs();

      var links = document.querySelectorAll('a');
         
      // Parcours des liens pour ajouter gestionnaire d'événement au clic
      for (var i = 0; i < links.length; i++) {
        links[i].addEventListener('click', confirmationSiCLickSUrLien);
      }
      // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
      return () => {      
        for (var i = 0; i < links.length; i++) {
          links[i].removeEventListener('click', confirmationSiCLickSUrLien);
        }
      };
    }, []);

    useEffect(() => {
      setAumoinsUneModif(false);
    }, [dataApi]);

    // pour react-select
    const [formesPrst4Selectionne, setFormesPrst4Selectionne] = useState(); // sera vide si l'utilisateur n'a pas fait de modif, tableau d'objet
    const [modifFormesPrst4, setModifFormesPrst4] = useState(false);
    function handleChangeFormesPrst4 (selectedOptions)  {
      setFormesPrst4Selectionne(selectedOptions);
      setModifFormesPrst4(true);
      setAumoinsUneModif(true);
    }
    const [publicsPrst4Selectionne, setPublicsPrst4Selectionne] = useState(); // sera vide si l'utilisateur n'a pas fait de modif, tableau d'objet
    const [modifPublicsPrst4, setModifPublicsPrst4] = useState(false);
    function handleChangePublicsPrst4 (selectedOptions)  {
      setPublicsPrst4Selectionne(selectedOptions);
      setModifPublicsPrst4(true);
      setAumoinsUneModif(true);
    }
    const [secteursPrst4Selectionne, setSecteursPrst4Selectionne] = useState(); // sera vide si l'utilisateur n'a pas fait de modif, tableau d'objet
    const [modifSecteursPrst4, setModifSecteursPrst4] = useState(false);
    function handleChangeSecteursPrst4 (selectedOptions)  {
      setSecteursPrst4Selectionne(selectedOptions);
      setModifSecteursPrst4(true);
      setAumoinsUneModif(true);
    }    
    const [thematiquesPrst4Selectionne, setThematiquesPrst4Selectionne] = useState(); // sera vide si l'utilisateur n'a pas fait de modif, tableau d'objet
    const [modifThematiquesPrst4, setModifThematiquesPrst4] = useState(false);
    function handleChangeThematiquesPrst4 (selectedOptions)  {
      setThematiquesPrst4Selectionne(selectedOptions);
      setModifThematiquesPrst4(true);
      setAumoinsUneModif(true);
    }
    // fin pour react-select
/*
  var testHidden = {
    type: "hidden"
  };
<FormControl id="hidden_marque_une_modif" infosProp={testHidden} />
*/
    return (
      <>
        {/*<hr /> */}
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageErreurApi message={messageProbleme} />

        { dataLoaded && dataApi && dataApi != null && props.infosProprietes != null && messageProbleme == null &&
        <Container className="my-0">
            {/*<div className='info-basic'>
              Formation {formationNumber} <br />
              Date de création : {dataApi.DATE_CREATIONfr} <br />
              Date de modification : {dataApi.DATE_MODIFfr} <br />
            </div>*/}

            <FormRHF onSubmit={onSubmit} onError={onError} dataApi={dataApi} gestionEvenements={gestionEvenements} >
              {props.infosProprietes["ACTIONS_FORMATION|other_dataEditable"].editable == 1 &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div style={{marginTop:"10px", marginBottom:"20px", minWidth: "700px"}}>
                    <MessageInfoChampsObligatoires />
                  </div>
                  <div>
                    <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                  </div>
                </div>
              }
              {props.infosProprietes["ACTIONS_FORMATION|other_dataEditable"].editable == 0 && props.infosProprietes["ACTIONS_FORMATION|other_dataEditable"].aide != "" &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <MessageInfoBox message={props.infosProprietes["ACTIONS_FORMATION|other_dataEditable"].aide} />
                </div>
              }

              <FormControl id="LIB_ACTIONS_FORMATION" infosProp={props.infosProprietes["ACTIONS_FORMATION|LIB_ACTIONS_FORMATION"]} />

              { props.infosGenFormation.certifOuPro == "certif" && props.infosProprietes["ACTIONS_FORMATION|other_possibiliteDemandeModifLib"].editable == 1 &&
                <div style={{display: "inline-block"}}>
                  <FormDemandeChangementFormation formationNumber={formationNumber} type="addPro" nbCi={dataApi.length} />
                </div>
              }

              {/* nécessite import Form de react-bootstrap */}
              {estFormationPrst4 &&
                <Form.Group className="mb-3 required">
                    <Form.Label>{props.infosProprietes["ACTIONS_FORMATION|other_dataFormesPrst4"].lib_form}</Form.Label>

                    {dataFormesPrst4 && dataFormesPrst4.length > 0 &&
                    <Select
                      isDisabled= {props.infosProprietes["ACTIONS_FORMATION|other_dataFormesPrst4"].editable == 0 && "disabled"}
                      onChange={handleChangeFormesPrst4}
                      closeMenuOnSelect={false}   
                      defaultValue={tabObjFormesPrst4Preselectionnes}
                      isMulti
                      options={dataFormesPrst4} />
                    }
                </Form.Group>
              }
              {estFormationPrst4 &&
                <Form.Group className="mb-3 required">
                    <Form.Label>{props.infosProprietes["ACTIONS_FORMATION|other_dataPublicsPrst4"].lib_form}</Form.Label>

                    {dataPublicsPrst4 && dataPublicsPrst4.length > 0 &&
                    <Select
                      isDisabled= {props.infosProprietes["ACTIONS_FORMATION|other_dataPublicsPrst4"].editable == 0 && "disabled"}
                      onChange={handleChangePublicsPrst4}
                      closeMenuOnSelect={false}   
                      defaultValue={tabObjPublicsPrst4Preselectionnes}
                      isMulti
                      options={dataPublicsPrst4} />
                    }
                </Form.Group>
              }
              {estFormationPrst4 &&
                <Form.Group className="mb-3 required">
                    <Form.Label>{props.infosProprietes["ACTIONS_FORMATION|other_dataSecteursPrst4"].lib_form}</Form.Label>

                    {dataSecteursPrst4 && dataSecteursPrst4.length > 0 &&
                    <Select
                      isDisabled= {props.infosProprietes["ACTIONS_FORMATION|other_dataSecteursPrst4"].editable == 0 && "disabled"}
                      onChange={handleChangeSecteursPrst4}
                      closeMenuOnSelect={false}   
                      defaultValue={tabObjSecteursPrst4Preselectionnes}
                      isMulti
                      options={dataSecteursPrst4} />
                    }
                </Form.Group>
              }
              {estFormationPrst4 &&
                <Form.Group className="mb-3 required">
                    <Form.Label>{props.infosProprietes["ACTIONS_FORMATION|other_dataThematiquesPrst4"].lib_form}</Form.Label>

                    {dataThematiquesPrst4 && dataThematiquesPrst4.length > 0 &&
                    <Select
                      isDisabled= {props.infosProprietes["ACTIONS_FORMATION|other_dataThematiquesPrst4"].editable == 0 && "disabled"}
                      onChange={handleChangeThematiquesPrst4}
                      closeMenuOnSelect={false}   
                      defaultValue={tabObjThematiquesPrst4Preselectionnes}
                      isMulti
                      options={dataThematiquesPrst4} />
                    }
                </Form.Group>
              }
              {estFormationPrst4 &&
                <FormSelect id="ID_PRST4_ECHELONS" infosProp={props.infosProprietes["ACTIONS_FORMATION|ID_PRST4_ECHELONS"]} options={dataPRST4_ECHELONS}/>
              }

              <FormControl id="OBJECTIF_FORMATION" infosProp={props.infosProprietes["ACTIONS_FORMATION|OBJECTIF_FORMATION"]} />
              <FormControl id="CONTENU_FORMATION" infosProp={props.infosProprietes["ACTIONS_FORMATION|CONTENU_FORMATION"]} />
              <FormControl id="resultats_attendus" infosProp={props.infosProprietes["ACTIONS_FORMATION|resultats_attendus"]} />
              <FormSelect id="ID_LHEO_PARCOURS_DE_FORMATION" infosProp={props.infosProprietes["ACTIONS_FORMATION|ID_LHEO_PARCOURS_DE_FORMATION"]} options={dataLHEO_PARCOURS_DE_FORMATION}/>
              <FormSelect id="Code_niveau_entree" infosProp={props.infosProprietes["ACTIONS_FORMATION|Code_niveau_entree"]} options={dataNiveauEuro}/>

              <FormSelect id="ID_LHEO_OBJECTIF_GENERAL_FORMATION" infosProp={props.infosProprietes["ACTIONS_FORMATION|ID_LHEO_OBJECTIF_GENERAL_FORMATION"]} options={dataLHEO_OBJECTIF_GENERAL_FORMATION}/>

              <FormControl id="Urlweb1" infosProp={props.infosProprietes["ACTIONS_FORMATION|Urlweb1"]} /> 
              {urlWeb2Visible && <FormControl id="Urlweb2" infosProp={props.infosProprietes["ACTIONS_FORMATION|Urlweb2"]} /> }
              {urlWeb3Visible && <FormControl id="Urlweb3" infosProp={props.infosProprietes["ACTIONS_FORMATION|Urlweb3"]} /> }


              {props.infosProprietes["ACTIONS_FORMATION|other_dataEditable"].editable == 1 &&
                <div className="partie-bouton-droite">
                  <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                </div>
              }

             <FormHidden id="hidden_marque_une_modif" />
          </FormRHF>
        </Container>
        }

        <LiensPrecedentSuivant  formationNumber={formationNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={aumoinsUneModif} />
      </>
    )

}

FormEtape2.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
  infosProprietes: PropTypes.object,
}

export default FormEtape2;